import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home/Home.vue'

import NotFound from '../views/NotFound/NotFound.vue'

import Payment from '../views/Payment/Payment.vue'
import Plugin from '../views/Plugin/Plugin.vue'
import PluginUrlId from '../views/Plugin/Plugin.vue'
import PluginInfo from '../views/PluginInfo/Info.vue'

const routes = [
  // { path: '/', name: 'redirect', redirect: '/en', component: Home },
  
  { path: '/en/payment/:PaymentReference', name: 'Payment', component: Payment },
  { path: '/plugin', name: 'Plugin', component: Plugin },
  { path: '/:lang/purchase/:urlid', name: 'PluginUrlId', component: PluginUrlId },
  { path: '/plugin-info', name: 'PluginInfo', component: PluginInfo },
  
  { path: '/:catchAll(.*)', component: NotFound },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
