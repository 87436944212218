<template>
    <div class="load-info" v-if="load">
        <div class="load load-200"></div>
    </div>

    <div class="error" v-if="!load && errorsPost.dataPurchase">
        <div class="box-error">
            <h3 class="title-secondary">Houve um erro</h3>
            <p>O Plugin DNE Pay não está configurado corretamente</p>
        </div>
    </div>

    <div class="plugin" v-if="!load && !errorsPost.dataPurchase">
        <div class="cont-plugin">
            <div class="column">
                <div class="box box-info">
                    <figure class="logo-dne-pay">
                        <img src="~@/assets/images/header/dne-pay.svg" alt="DNE Pay">
                    </figure>

                    <p><strong>Utilizando o DNE Token para comprar, você ganha <span>10% de cashback</span> para suas próximas compras em lojas filiadas.</strong></p>
                    <!-- <p class="cta"><strong>Não atualize, simplifique!!!</strong></p> -->
                </div>

                <div class="box items">
                    <div class="item"
                        :class="{multi: dataPurchase.items.length > 1}"
                        v-for="(listItem, index) in dataPurchase.items"
                        :key="index">
                        
                        <div class="photo">
                            <img src="https://cdn.dnemarket.com/product_variant_attachment_default.png" v-if="!listItem.image_url">
                            <img :src="listItem.image_url" :alt="listItem.name">
                        </div>

                        <div class="details">
                            <h3>{{listItem.name}}</h3>
                            
                            <div class="variables">
                                <span>{{ productDescription(listItem.description, 115) }}</span>
                            </div>

                            <div class="price">
                                <div>
                                    <div class="cashback">
                                        <small>Cashback</small>
                                        <h3>{{listItem.cashback.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</h3>
                                    </div>
                                </div>

                                <div>
                                    <span>{{ listItem.price.toLocaleString("pt-PT", {style:"currency", currency:parameters.instrument.substring(0,3)}) }}</span>
                                    <h3>{{listItem.amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="total" v-if="dataPurchase.items.length > 1">
                        <div>
                            <strong>Total</strong>
                            <span>{{ dataPurchase.price.toLocaleString("pt-PT", {style:"currency", currency:parameters.instrument.substring(0,3)}) }}</span>
                            <h3>{{ dataPurchase.amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 }) }}</h3>
                        </div>
                        
                        <div class="cashback">
                            <small>Cashback</small>
                            <h3>{{ dataPurchase.cashback.toLocaleString("pt-PT", { maximumFractionDigits: 4 }) }}</h3>
                        </div>
                    </div>
                </div>

                
                <!-- REGISTER -->
                <div class="box box-register">
                    <div class="register">
                        <p>Ainda não faz parte do <strong>Ecossistema DNE</strong></p>

                        <a :href="`${VUE_APP_PLATFORM_URL}${dataPurchase.store.language}/account/register?utm_content=${dataPurchase.store.account_id}&utm_source=dne-pay&utm_campaign=plugin`" target="_blank" class="btn-primary btn-register">
                            Saiba mais
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import apiPayPlugin from '@/config/apiPayPlugin.js'

    export default {
        data(){
            return {
                flagSelected: "en",
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,

                load: true,

                parameters: {
                    external_id: "",
                    campaign_id: "",
                    store_id: "",
                    instrument: "",
                    country: "",
                    items: [
                        {
                            external_id: "",
                            product_variant_id: "",
                            name: "",
                            description: "",
                            image_url: "",
                            price: ""
                        }
                    ]
                },

                dataPurchase: {},
                
                errorsPost: {
                    dataPurchase: false,
                },
                
                newtk: "",
                linkInternacional: "",
            }
        },

        async mounted() {
            this.checkPurchase()
        },


        methods: {
            checkPurchase(){
                const urlParams = new URLSearchParams(window.location.search);

                this.parameters.external_id = urlParams.get('external_id');
                this.parameters.campaign_id = urlParams.get('campaign_id');
                this.parameters.store_id = urlParams.get('store_id');
                this.parameters.instrument = urlParams.get('instrument');
                this.parameters.country = urlParams.get('country');
                
                // ITEMS
                const itemsJson = urlParams.get('items');
                if (itemsJson) {
                    try {
                        this.parameters.items = JSON.parse(decodeURIComponent(itemsJson));
                    } catch (error) {
                        console.error('Erro ao decodificar os itens:', error);
                    }
                } else {
                    this.parameters.items = [];
                }

                apiPayPlugin.post(`/api/v2/pay/plugin/checkout`, this.parameters)
                .then(response => {
                    this.dataPurchase = response.data
                    
                    setTimeout(() => {
                        this.load = false;
                    }, 500);
                })
                .catch(error => {
                    this.errorsPost.dataPurchase = error
                    this.$toast.error("Houve um erro")

                    setTimeout(() => {
                        this.load = false;
                    }, 500);
                })
            },
            
            
            // PURCHASE DNE TOKEN
            purchaseDNEToken(){
                this.newtk = localStorage.getItem('accessToken')
                this.linkInternacional = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space/purchase`
                window.open(this.linkInternacional, '_blank');
            },

            
            
            productDescription(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + "...";
                }
                return text;
            },

            maskedEmail(email) {
                if (!email) return '';
                const [localPart, domain] = email.split('@');
                if (!localPart || !domain) return email;
                const visibleStart = localPart.slice(0, 2);
                const maskedLocalPart = `${visibleStart}****`;
                return `${maskedLocalPart}@${domain}`;
            },

            maskedPhone(phoneNumber) {
                if (!phoneNumber) return ''
                const visibleStart = phoneNumber.slice(0, 2)
                const visibleEnd = phoneNumber.slice(-2)

                return `${visibleStart}****${visibleEnd}`
            },

            scrollPage(id){
                const anc = document.getElementById(id);
                
                setTimeout(() => {
                    anc.scrollIntoView({ behavior: "smooth" });
                }, 1000);
            }
        }
    }
</script>


<style src="./custom-info.scss" lang="scss" scoped />